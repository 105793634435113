<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      >

      <v-row>
        <v-col cols="4">
          <v-text-field
            :label='$t("visitor.visitorid")'
            :value='$t("visitor.idappears")'
            outlined
            readonly
            filled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            :label='$t("visitor.firstname")'
            v-model="firstname"
            outlined
            :rules="[rules.required, rules.counter]"
            clearable
            required
            counter
            maxlength="128"
          ></v-text-field>

          <v-menu
            ref="birthdate"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                :label='$t("visitor.birthdate")'
                hint="DD.MM.YYYY format"
                persistent-hint
                prepend-icon="mdi-calendar"
                required
                :rules="[rules.required, rules.date]"
                clearable
                v-bind="attrs"
                @blur="birthdate = parseDate(dateFormatted)"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthdate"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            outlined
            label="email"
            required
            clearable
            counter
            maxlength="128"
            prepend-icon="mdi-email"
          ></v-text-field>

          <!-- anonymous_id -->
          <!-- date_joined  -->
          <v-text-field
            v-model="height"
            :label='$t("visitor.height")'
            prepend-icon="mdi-account-arrow-up"
            clearable
            outlined
            required
            type="number"
            :rules="[rules.required]"
          ></v-text-field>

        </v-col>

        <v-col cols="4">
          <v-text-field
            :label='$t("visitor.lastname")'
            v-model="lastname"
            :rules="[rules.required, rules.counter]"
            outlined
            clearable
            counter
            required
            maxlength="128"
          >
          </v-text-field>

          <v-select
            :items="['male','female']"
            v-model="sex"
            :label='$t("visitor.sex")'
            clearable
            outlined
            required
            prepend-icon="mdi-gender-male-female"
            :rules="[rules.required]"
          >
          </v-select>

          <v-text-field
            v-model="phone"
            :label='$t("visitor.phone")'
            required
            clearable
            outlined
            prefix="+"
            prepend-icon="mdi-phone"
            :rules="[rules.required, rules.phone]"
          ></v-text-field>

          <v-text-field
            v-model="weight"
            :label='$t("visitor.weight")'
            prepend-icon="mdi-weight"
            clearable
            outlined
            required
            :rules="[rules.required]"
            type="number"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            :label='$t("visitor.patronymic")'
            v-model="patronymic"
            :rules="[rules.counter]"
            counter
            maxlength="128"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12" md="12">
          <v-textarea
            v-model="comments"
            :label='$t("comments")'
            clearable
            outlined
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
          <v-btn
            class="ma-2"
            @click="onCancel"
          >
            {{$t("cancel")}}
          </v-btn>

          <v-btn
            class="ma-2"
            color="primary"
            :disabled="!valid"
            @click="onOk"
          >OK</v-btn>

      </v-row>

    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';
// import App from './App.vue'
  export default {
    name: 'Visitor',

    // components: {
    //   app: App
    // },

    data: () => ({
      firstname:"",
      lastname:"",
      patronymic:"",
      birthdate:"",
      dateFormatted:"",
      email:"",
      phone:"",
      height:170.0,
      weight:70.0,
      sex:"male",
      comments:"",
      visitorID:"ID appears after save",
      menu1 : false,

      valid : false,

      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 128 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        phone: value => {
          const pattern = /^\d{10}$/;
          return pattern.test(value) || 'Invalid phone number.'
        },
        date: value => {
          const pattern = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
          return pattern.test(value) || 'Invalid date format.'
        }
      },

    }),

    mounted() {
      this.$refs.form.validate();
    },

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.birthdate)
      },
    },

    watch: {
      birthdate (val) {
        this.dateFormatted = this.formatDate(this.birthdate)
      },
    },

    methods : {

      onCancel () {
        //TODO сброс формы
        //переход на Main
        this.$router.replace({name:"Main"});
      },

      onOk () {
        axios.post("/api/visitor.add",{
          first_name:this.firstname,
          last_name : this.lastname,
          patronymic : this.patronymic,
          email : this.email,
          phone : this.phone,
          height : this.height,
          weight : this.weight,
          comments : this.comments,
          sex : this.sex,
          date_birth:this.birthdate,
        })
        .then( response => {
          console.log(response);
          if(response.data.status  != "ok") {
              this.$emit("showerror","Не удалось добавить посетителя");
              return;
            } else {
              this.$emit("showmessage","Добавлен посетитель " + response.data.anonymous_id);
              this.$router.replace({name:"Main"});
            }
        })
        .catch( error => {
          console.log(error);
          this.$emit('showerror', error);
        });
      },

      formatDate (date) {
        // преобразование отображаемой даты (через точки)
        // в формат переменной, ISO (через тире)
        console.log("formatDate(), date = ", date);
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}.${month}.${year}`
      },

      parseDate (date) {
        // в формат переменной, ISO (через тире)
        // преобразование отображаемой даты (через точки)
        console.log("parseDate(), date = ", date);
        if (!date) return null
        const [day, month, year] = date.split('.');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    }
  }
</script>
