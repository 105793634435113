import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Axios from 'axios'
// import Login from './views/Login.vue'
// import Main from './views/Main.vue'
// import Visitor from './views/Visitor.vue'
// import Session from './views/Session.vue'

// для TimePicker в стиле tumbler
import DateTimePicker, { DatePicker, TimePicker } from 'vue-drumroll-datetime-picker'
import 'vue-drumroll-datetime-picker/dist/style.css'
import i18n from './i18n'
Vue.component('DateTimePicker', DateTimePicker)
Vue.component('TimePicker', TimePicker)

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Token ' + token
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.state.token) {
            next({
                name: 'Login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

// код с хакатона
// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//       if (!store.state.isAuthenticated) {
//         next({
//           name: 'Login',
//           query: { redirect: to.fullPath }
//         })
//       } else if (to.matched.some(record => record.meta.groups && !record.meta.groups.includes(store.state.user.role))) {
//         next(
//           {
//             name: "Index"
//           }
//         )
//       } else {
//         next()
//       }
//     } else {
//       next()
//     }
//   })

Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,

    beforeCreate() {
        this.$store.commit('initialiseStore');
    },

    i18n,
    render: h => h(App)
}).$mount('#app');

/*const app = new Vue({
    vuetify,
    router,
    store,
    beforeCreate() {
        this.$store.commit('initialiseStore');
    },
    render: h => h(App),
}).$mount('#app')
app.use(store)
store.state.app = app
app.$mount('#app')*/