import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        username: null,
        app: null,
        error_show: false,
        error_string: "",
        searchstring: "",
        selected_language:"en",
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getUsername(state) {
            return state.username;
        },
        getSearchstring(state) {
            return state.searchstring;
        },
        getSelectedLanguage(state) {
            return state.selected_language;
        }
    },
    mutations: {
        initialiseStore(state) {
            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token');
            }
            if (localStorage.getItem('username')) {
                state.username = localStorage.getItem('username');
            }
            if (localStorage.getItem('searchstring')) {
                state.searchstring = localStorage.getItem('searchstring');
            }
        },
        setToken(state, data) {
            state.token = data;
            localStorage.setItem("token", data);

        },
        setUsername(state, data) {
            state.username = data;
            localStorage.setItem("username", data);
        },
        setSearchstring(state, data){
            state.searchstring = data;
            localStorage.setItem("searchstring", data);
        },
        setSelectedLanguage(state, data) {
            state.selected_language = data;
            localStorage.setItem("selected_language", data);
        }
    },
    actions: {

    },
    modules: {

    }
})