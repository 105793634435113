<template>
  <v-container no-gutters fluid>
  <v-form
    ref="form"
    v-model="valid">
      <v-row class="mx-1">
        <!-- колонка с полями -->
        <v-col cols="12" md="4">
          <v-row>
            <v-col>
              <v-text-field
                v-model="visitorID"
                :label="$t('session.visitorid')"
                outlined
                readonly
                background-color="grey lighten-3"
                ></v-text-field>

              <v-text-field
                v-model="session_name"
                :label="$t('session.sessionname')"
                :rules="[rules.required]"
                outlined
                required
                background-color="grey lighten-3"
                readonly></v-text-field>
              <!-- v-model="session_name" -->
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <!-- меню выбора даты (picker) -->
              <v-menu ref="visitdate" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    :label="$t('session.visit_date')"
                    hint="DD.MM.YYYY format"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on" outlined required
                    :rules="[rules.required, rules.date]"
                    :readonly="all_readonly"
                  ></v-text-field>
                  <!--  type="datetime-local" -->
                </template>
                <v-date-picker v-model="date" no-title :locale="$i18n.locale" @input="menu1 = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <!-- <v-text-field
                v-model="time"
                label="Visit time"
                prepend-icon="mdi-clock-outline"
                clearable
                outlined
                required
                :rules="[rules.required, rules.time]"
              ></v-text-field> -->
              <date-time-picker
                style="font-size: 21px"
                v-model="time"
                type="time"
                minute-interval="10"
                dialog
                hide-overlay required>
                <template v-slot:activator="{ on }">
                  <v-text-field v-on="on" v-model="time" :label="$t('session.visit_time')"
                    prepend-icon="mdi-clock-outline" clearable outlined required :rules="[rules.required, rules.time]"
                    :readonly="all_readonly">
                    {{ time }}
                  </v-text-field>
                </template>
              </date-time-picker>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="duration"
                :label="$t('session.visit_duration')"
                prepend-icon="mdi-av-timer"
                outlined
                readonly
                background-color="grey lighten-3"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="12" md="12">
              <v-textarea
                v-model="comments"
                :label="$t('comments')"
                clearable
                outlined
                :readonly="all_readonly"
                class="fill-height"
                >
              </v-textarea>
            </v-col>
          </v-row>
        </v-col>

        <!-- колонка с деревом -->
        <v-col cols="12" md="4">
        <v-sheet
            class="fill-height"
            fill-height
            rounded
            :style="(session_catalogue_id===null)?'border: 2px solid red;':'border: 1px solid lightgray;'"
            hint="asfdasfd"
            :persistent-hint="true"
            messages="asfdasfd"
          >
            <v-alert :type="(session_catalogue_id===null)?'error':'success'">
              {{(session_catalogue_id===null)
                ?'Choose session from catalogue'
                :'Session chosen'}}
            </v-alert>
            <v-treeview
              v-model="treemodel"
              :items="protocols"
              :item-children="'sessions'"
              :active.sync="selected_session"
              :value="treevalue"

              @update:active="treeSelected"
              activatable
              item-key="name" open-on-click transition return-object>
              <!-- <template v-slot:prepend="{ item, open }"> -->
              <!-- <v-icon v-if="!item.file">
                {{ open ? 'mdi-account-details' : 'mdi-account-clock' }}
              </v-icon>
              <v-icon v-else>
                {{ files[item.file] }}
              </v-icon> -->
              <template v-slot:prepend="{ item }">
                <v-icon v-if="!item.sessions"> mdi-account-clock </v-icon>
                <v-icon v-else> mdi-account-details </v-icon>
              </template>
            </v-treeview>
          </v-sheet >
          <!-- <v-span
            v-if="session_catalogue_id===null"
            >
            Required.
          </v-span> -->
          <!-- выбор сессии на основе v-list с группами
          не позволяет сделать исключающее выделение (exclusive)
          <v-list>
            
            
              <v-list-group
                v-for="protocol in protocols"
                :key="protocol.id"
                v-model="protocol.active"
                prepend-icon="mdi-account-details"
                no-action
                
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="protocol.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item-group>
                  <v-list-item
                    v-for="session in protocol.sessions"
                    :key="session.id"
                  >

                    <v-list-item-icon>
                      <v-icon>mdi-account-clock</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        v-text="session.name"
                      ></v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </v-list-item-group>
              </v-list-group>
          </v-list> -->
        </v-col>

        <!-- колонка с календарём -->
        <v-col cols="12" md="4">
          <v-calendar
            ref="calendar"
            v-model="$props.date"
            color="primary"
            first-time="9:00"
            first-interval="60"
            interval-count="14"
            :locale="$i18n.locale"
            :weekdays="
              $i18n.locale === 'ru'
                ? [1, 2, 3, 4, 5, 6, 7, 0]
                : [0, 1, 2, 3, 4, 5, 6, 7]
            "
            :interval-format="intervalFormatter"
            type="day"
            :events="events_with_current"
            :event-color="getEventColor"
            @change="fetchEvents"
          ></v-calendar>
        </v-col>
      </v-row>

      <!-- row с кнопками формы -->
      <v-row no-gutters>
        <v-btn @click="onCancel" class="ma-2">{{ $t("cancel") }} </v-btn>

        <v-btn color="primary" :disabled="!valid" @click="onSubmit" class="ma-2">OK</v-btn>
      </v-row>
    
  </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Session",

  // /session/new/:visitorID
  // /session/edit/:sessionID
  props: {
    visitorID: String,
    sessionID: String,
    date: String,
    create_new: Boolean,
  },

  data: () => ({
    // visitorID : null,
    session_name: null,
    duration: 0,
    comments: "",
    session_catalogue_id: null,
    // date : "",
    time: "",
    valid: false,
    dateFormatted: "",
    menu1: false,
    all_readonly: false,
    treemodel: [],
    events:[],
    protocols: [
      // FIXME id у протоколов и сессий должен быть разный
      // чтобы использовать как key дерева
      // {
      //   id: 1,
      //   name: "Протокол 1",
      //   active: true,
      //   sessions: [{ id: 1, name: "Сеанс 1" }],
      // },
      // {
      //   id: 2,
      //   name: "Протокол 2",
      //   active: true,
      //   sessions: [
      //     { id: 2, name: "Сеанс 2" },
      //     { id: 3, name: "Сеанс 3" },
      //   ],
      // },
      // {
      //   id: 3,
      //   name: "Протокол 3",
      //   active: true,
      //   sessions: [{ id: 4, name: "Сеанс 4" }],
      // },
    ],
    selected_session: [],
    treevalue: [],
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 128 || "Max 20 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      phone: (value) => {
        const pattern = /^\d{10}$/;
        return pattern.test(value) || "Invalid phone number.";
      },
      time: (value) => {
        const pattern = /\b([01][0-9]|2[0-3]):([0-5][0-9])\b/;
        return pattern.test(value) || "Invalid time value.";
      },
    },
  }),

  created() {
    console.log("created(), params = ", this.$route.params);
    if (this.$route.params.existingID) {
      this.all_readonly = true;
      // axios.get( "/api/sessions", { params: { date_visit: date_to_request.toISOString()} })
      // .then(response => {})
      // .catch(error => { })
    } else {
      this.all_readonly = false;
    }
  },

  mounted() {
    console.log("props = ", this.$props);
    this.$refs.form.validate();
    console.log("this.dateFormatted = ", this.dateFormatted);
    this.dateFormatted = this.formatDate(this.date);
    console.log("this.dateFormatted = ", this.dateFormatted);
    this.updateTree();
  },

  // computed: {
  //   computedDateFormatted () {
  //     return this.formatDate(this.date)
  //   },
  // },

  computed: {
    events_with_current(){
      if(this.selected_session.length!==1 || this.time==''){
        return this.events;
      }
      
      var result = [...this.events];
      console.log("this.date = ", this.date);
      console.log("this.dateFormatted = ", this.dateFormatted);
      console.log("Date(this.date) = ", new Date(this.date));
      const [hours, minutes] = this.time.split(':');
      console.log("[minutes, minutes] = ", [hours*60, minutes]);
      const tmp_start = new Date(this.date + " " + this.time);
      console.log("tmp_start = ", tmp_start);
      const tmp_end = new Date(
        new Date(tmp_start).setMinutes(
          new Date(tmp_start).getMinutes()
          + this.duration));
      console.log("tmp_end = ", tmp_end);  
      result.push({
        name: this.session_name,
        start: tmp_start,
        end: tmp_end,
        color: "red",
        timed : true,
        id : 0,
        comments : this.comments,
        anonymous_id : this.$props.visitorID,
      });
      console.log("events_with_current(): result = ", result);
      return result;
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },

    // date: function(){
    //       deep : true,
    //       // immediate : true,
    //       handler(newValue, oldValue) {
    //           console.log(newValue);
    //       },
    // },

    // подвешивает намертво
    // dateFormatted (val) {
    //   this.date = this.formatDate(this.date)
    // },
  },

  methods: {
    treeSelected(value) {
      // console.log("treeSelected(): selected_session=", this.selected_session);
      // console.log(
      //   "treeSelected(): selected_session.id=",
      //   this.selected_session[0].id
      // );
      // console.log(
      //   "treeSelected(): selected_session.name=",
      //   this.selected_session[0].name
      // );
      // console.log(
      //   "treeSelected(): selected_session.duration=",
      //   this.selected_session[0].duration
      // );
      if(this.selected_session.length===1){
        this.session_name = this.selected_session[0].name;
        this.duration = this.selected_session[0].duration;
        this.session_catalogue_id = this.selected_session[0].id;
      }
      else{
        this.session_name = null;
        this.duration = null;
        this.session_catalogue_id = null;
      }

      // console.log("treeSelected(): selected_session[0]=", this.selected_session[0]);
      // console.log("treeSelected(): selected_session.length=", this.selected_session.length);
      // console.log("treeSelected(): value=", value);
      // console.log("treeSelected(): treevalue.length=", this.treevalue.length);
      // console.log("treeSelected(): treemodel.length=", this.treemodel.length);
      // const key = this.selected_session[0];
      // var item = this.protocols.find(item => item.name == "s1");
      // console.log("treeSelected(): item = ", item);
    },

    updateTree() {
      axios
        .get("/api/protocols_catalogue", { params: { sessions: 1 } })
        .then((response) => {
          // console.log("updateTree(), response.data = ", response.data);
          this.protocols = response.data;
        })
        .catch((error) => {
          //TODO сообщение об ошибке
        });
    },

    onCancel() {
      //TODO сброс формы
      //переход на Main
      this.$router.replace({ name: "Main" });
    },

    onSubmit() {
      axios
        .post(
          "/api/session.add",
          {
            // visitor: this.visitorID,
            datetime_visit: this.date + " " + this.time,
            // datetime_scan: null, // заполняется на следующем этапе
            // file_scan: null, // заполняется на следующем этапе
            // file_program : null, // заполняется на следующем этапе
            session_catalogue_id: this.session_catalogue_id, //TODO
            // duration_minutes: this.duration, //TODO убрать
            // name: this.session_name, //TODO убрать, заменить на ID
            comments: this.comments,
            anonymous_id: this.visitorID.toUpperCase(),
          }
          //  {
          //   headers: {
          //   'Authorization':'Token 3366be2a79a28cbf82c2c4d8329e7dd36d155298'
          //   }
          //   }
        )
        .then((response) => {
          //TODO переделать под сессию
          if (response.data.status != "ok") {
            this.$emit(
              "showerror",
              "Не удалось добавить сессию. " + response.data.detail
            );
            return;
          } else {
            this.$emit("showmessage", "Cессия добавлена");
            this.$router.replace({ name: "Main" });
          }
        })
        .catch((error) => { });

      return;
    },

    formatDate(date) {
      // преобразование отображаемой даты (через точки)
      // в формат переменной, ISO (через тире)

      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },

    parseDate(date) {
      // в формат переменной, ISO (через тире)
      // преобразование отображаемой даты (через точки)

      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getEventColor (event) { // метод для назначения цвета сессии в календаре
      //TODO разные цвета для текущих, пройденных и следующих сессий
      return event.color;
    },

    fetchEvents ({ start, end }) { // загрузка запланированных сессий в календарь
      console.log("fetchEvents(), start=",start);
      console.log("fetchEvents(), end=",end);
      let date1 = new Date(start.date);
      //TODO получить с сервера уже запланированные сессии
      this.events = [];
      const tmp_events = [];
      axios.get( "/api/sessions", { params: { date_visit: date1.toISOString()} })
      .then(response => {
        console.log( "response=", response );
        if(response.data.status!="ok"){
          this.error_show=true;
          this.error_text="Ошибка при запросе сеансов";
          return;
        }
        response.data.sessions.forEach((element) => {
          // console.log("element=", element);
          const tmp_name = element.name;// + " " + element.comments;
          const tmp_start = new Date(element.datetime_visit);
          const tmp_end = new Date(new Date(element.datetime_visit).setMinutes(new Date(element.datetime_visit).getMinutes()+element.duration_minutes));

          tmp_events.push({
              name: tmp_name,
              start: tmp_start,
              end: tmp_end,
              color: "blue",
              timed : true,
              id : element.id,
              comments : element.comments,
              anonymous_id : element.anonymous_id,
          });
          this.events = tmp_events;
        })
      })
      .catch(error => {
      //  this.error_show=true;
      //  this.error_text=error;
      });
    },

    intervalFormatter(locale) {
      return locale.time;
    },
  },
};
</script>
