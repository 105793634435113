<template>
  <!-- <v-container> -->
    <v-row
      class="mb-6"
      style="height: 100%;"
      no-gutters
      fill-height
      >

      <!-- левая колонка со списком посетителей -->
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="ps-2"
        no-gutters
        align-self="start"
        style="height: 100%;"
      >
        <v-card tile class="fill-height d-flex flex-column"> 
          <!-- элементы в заголовке списка посетителей (поиск) -->
          <v-toolbar> 
            <v-toolbar-title> {{$t("main.visitors")}}</v-toolbar-title>
            <v-spacer/>
            <v-text-field
              :label='$t("main.search_visitor")'
              v-if="this.$route.name == 'Main'"
              v-model="visitor_searchstring"
              filled
              rounded
              clearable
              dense
              hide-details="true"
              @keypress.enter="visitorSearch"
              @click:clear="visitor_searchstring=''"
            >
              <template v-slot:append>
                <v-btn color="primary" large icon class="mt-n2 mr-n5" @click="visitorSearch">
                  <v-icon large> mdi-magnify </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-toolbar>

          <!-- элементы под заголовком списка посетителей (кнопки, чекбокс) -->
          <v-row class="ma-2" no-gutters>
            <v-btn
              class="ma-2"
              outlined
              color="primary"
              @click="newVisitor"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
              {{$t("add")}}
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red"
              @click="confirmDeleteVisitor"
              :disabled="selectedVisitor==undefined"
            >
              <v-icon dark>
                mdi-delete
              </v-icon>
              {{$t("delete")}}
            </v-btn>
            <v-spacer/>
            <v-checkbox
              v-model="selectall"
            >
            </v-checkbox>
          </v-row>
          
          <!-- список посетителей -->
          <v-list
            class="text-left overflow-y-auto fill-height"
            two-line
          > 
            <!-- v-list-item-group - компонент, управляющий состоянием списка (выделено/не выделено) -->
            <v-list-item-group
              v-model="selectedVisitor"
            >
            <template
              v-for="(item, index) in filtered_visitors">
              
              <v-divider
                :v-show="index == 0"
                :key="index*2">
              </v-divider>
              
              <v-list-item 
                :key="item.anonymous_id"
                :value="item"
                @contextmenu="showVisitorContextMenu($event, item)"
                >
                <!-- @click="list_item_click" -->
                <template v-slot:default="{ active }">
                <!-- <v-divider
                  inset
                  :v-show="index == 0"
                  :key="index*2">
                </v-divider> -->
                <v-list-item-avatar :color="item.sex==='Male'?'blue':'red'">
                  <!-- <v-icon>mdi-account</v-icon> -->
                  <v-icon v-if="item.sex==='Male'">mdi-face-man-outline</v-icon>
                  <v-icon v-if="item.sex==='Female'">mdi-face-woman-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="d-flex">
                    <div class="mr-2 font-weight-black">
                      {{item.last_name}}
                    </div>
                    <div class="mr-2 font-weight-black">
                      {{item.first_name}}
                    </div>
                    <v-chip class="ml-auto">
                      Age: {{getAge(item.date_birth)}}
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row>
                      <v-col>ID:{{item.anonymous_id}}</v-col>
                      <v-col><v-icon>mdi-phone</v-icon> {{item.phone}}</v-col>
                      <v-col><v-icon>mdi-email</v-icon>{{item.email}}</v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  >
                    <!-- v-model="selected_users" -->
                    <!-- :value="item.anonymous_id" -->
                    <!-- :input-value="item.anonymous_id" -->
                  </v-checkbox>
                </v-list-item-action>
                </template>
                <!-- <v-divider
                  inset
                  :key="index*2 + 1"
                ></v-divider> -->

              </v-list-item>

              <v-divider
                :key="index*2 + 1"
              ></v-divider>

            </template> 
            </v-list-item-group>
          </v-list>

          <!-- контекстное меню посетителя -->
          <!--             v-if="selectedVisitor!=undefined" -->
          <!--
          offset-y
          offset-x
          :ripple="false"-->
          
          <v-menu
            v-model="visitorMenuOpen"
            :close-on-content-click="true"
            :activator="selectedVisitorElement"
            :position-x="x_visitor_menu"
            :position-y="y_visitor_menu"
            :close-on-click="true"
            absolute
            offset-y
          >
            <v-card
              color="grey lighten-4"
              flat
            >
              <v-card-title class="d-inline-block text-truncate"  style="max-width: 400px">
                {{(selectedVisitor==undefined)?'':selectedVisitor.first_name}}
              </v-card-title>
              <v-card-subtitle align="start" justify="start">
              <div>
                <span class="d-inline-block text-truncate" style="max-width: 400px">
                User ID: {{(selectedVisitor==undefined)?'':selectedVisitor.anonymous_id}}
                </span>
                <br>
                <span class="d-inline-block text-truncate" style="max-width: 400px">
                Last Name: {{(selectedVisitor==undefined)?'':selectedVisitor.last_name}}
                </span>
                <br>
                <span class="d-inline-block text-truncate" style="max-width: 400px">
                Comments: {{(selectedVisitor==undefined)?'':selectedVisitor.comments}}
                </span>
                </div>
              </v-card-subtitle>
              <v-list>

                <v-list-item  @click="editVisitor" link>
                  <v-list-item-icon>
                    <v-icon>mdi-pen</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Редактировать</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="confirmDeleteSession" link>
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{$t("delete")}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>                  
        </v-card>
      </v-col>

      <!-- правая колонка с календарём -->
      <v-col
        cols="12"
        sm="12"
        md="8"
        class="ps-2"
        no-gutters
        align-self="start"
        style="height: 100%;"
      >

        <v-card class="fill-height d-flex flex-column">

          <!-- элементы в заголовке календаря/расписания (происк) -->
          <v-toolbar> 
            <v-toolbar-title> {{$t("main.sessions")}} </v-toolbar-title>
            <v-spacer/>
            <v-text-field
              :label='$t("main.search_session")'
              v-if="this.$route.name == 'Main'"
              v-model="session_searchstring"
              filled
              rounded
              clearable
              dense
              hide-details="true"
              @click:clear="session_searchstring=''"
              @keypress.enter="sessionSearch"
            >
              <template v-slot:append>
                <v-chip v-if="session_search_model.length!==0">
                  {{ (session_search_counter + 1) + '/' + session_search_model.length}}
                </v-chip>

                <v-btn
                  v-if="session_search_model.length!==0"
                  color="primary"
                  large
                  icon
                  class="ma-1 mt-n2"
                  @click="sessionSearchPrevious">
                  <v-icon large> mdi-chevron-up </v-icon>
                </v-btn>

                <v-btn
                  v-if="session_search_model.length!==0"
                  color="primary"
                  large
                  icon
                  class="ma-1 mt-n2"
                  @click="sessionSearchNext">
                  <v-icon large> mdi-chevron-down </v-icon>
                </v-btn>

                <v-btn
                  color="primary"
                  large
                  icon
                  class="mt-n2 mr-n5"
                  @click="sessionSearch">
                  <v-icon large> mdi-magnify </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-toolbar>

          <!-- элементы под заголовком календаря/расписания (кнопки) -->
          <v-row class="ma-2"> 
            <v-btn
              class="ma-2"
              outlined
              color="primary"
              @click="newSession"
              :disabled="selectedVisitor==undefined"
            >
              <!-- :disabled="selectedVisitor==undefined" -->
              <!-- :disabled="selected_users.length!==1" -->
              <v-icon dark>
                mdi-plus
              </v-icon>
              {{$t("add")}}
            </v-btn> 
            <v-spacer></v-spacer>
            <v-select
              v-model="type"
              :items="types"
              dense
              outlined
              class="ma-2 nowrap auto"
              label="type"
              hide-details="true"
            ></v-select>
            <v-btn
              outlined
              class="ma-2"
              color="grey darken-2"
              @click="setToday"
            >
              {{ $t('main.today') }}
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>

          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            first-time="9:00"
            first-interval="60"
            interval-count="14"
            :locale="$i18n.locale"
            :weekdays="($i18n.locale==='ru')?[1,2,3,4,5,6,7,0]:[0,1,2,3,4,5,6,7]"
            :interval-format="intervalFormatter"
            :type="type"
            :events="events"
            :event-color="getEventColor"
            @change="fetchEvents"
            @click:event="showEvent"
          ></v-calendar>
          
          <!-- контекстное меню календаря -->
          <v-menu
            v-model="calendarMenuOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              flat
            >
              <v-card-title class="d-inline-block text-truncate"  style="max-width: 400px">
                {{selectedEvent.name}}
              </v-card-title>
              <v-card-subtitle align="start" justify="start">
              <div>
                <span class="d-inline-block text-truncate" style="max-width: 400px">
                User ID: {{selectedEvent.anonymous_id}}
                </span>
                <br>
                <span class="d-inline-block text-truncate" style="max-width: 400px">
                Comments: {{selectedEvent.comments}}
                </span>
                </div>
              </v-card-subtitle>
              <v-list>

                <v-list-item  @click="editSession" link>
                  <v-list-item-icon>
                    <v-icon>mdi-pen</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Редактировать</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="confirmDeleteSession" link>
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{$t("delete")}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <v-btn color="primary">Edit</v-btn>
              <v-btn color="error">Delete</v-btn> -->
            </v-card>
          </v-menu>

        </v-card>
      </v-col>

      <!-- диалог подтверждения удаления посетителей -->
      <v-overlay
        :value="show_overlay"
      >
        <v-card class="mx-auto" height="150" width="300" color="white">
          <v-card-text>
            <div class="text--primary">Удалить посетителей с ID {{selected_users}}?</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" @click="deleteVisitor" class="ma-2">
              Удалить
            </v-btn>
            <v-btn @click="show_overlay=false" class="ma-2">
              Отмена
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>

      <!-- диалог подтверждения удаления сессии -->
      <v-overlay
        :value="show_overlay_delete_session"
      >
        <v-card class="mx-auto" height="150" width="300" color="white">
          <v-card-text>
            <div class="text--primary">Архивировать сессию?</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" @click="deleteSession" class="ma-2">
              Удалить
            </v-btn>
            <v-btn @click="show_overlay_delete_session=false" class="ma-2">
              Отмена
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-row>
    
  <!-- </v-container> -->

</template>

<script>
import axios from 'axios'
import { on } from 'events';
import { appendFile } from 'fs';
  export default {
    name: 'Main',

    data: () => ({
      calendarMenuOpen : false,
      visitorMenuOpen : false,
      x_visitor_menu : 0,
      y_visitor_menu : 0,
      selectedEvent: {},
      selectedElement: null,
      selectedVisitor: null, // объект/список объектов выделенного посетителя
      selectedVisitorElement: null, // элемент, по которому был выполнен клик мыши в списке
      selectall : false,
      focus: '',
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      categories: ['Сеансы'],
      type: '4day',
      types: ['month', 'week', '4day', 'day'],
      users:[],
      sessions:[],
      selected_users:[], // список номеров в списке выделенных посетителей
      visitors:[
        // {firstname:"Ivan", lastname:"Ivanov", id:"12AB"},
        // {firstname:"Ivan", lastname:"Ivanov", id:"34CD"},
        // {firstname:"Ivan", lastname:"Ivanov", id:"56EF"},
      ],
      show_overlay : false,
      show_overlay_delete_session : false,

      visitor_searchstring : "",

      session_searchstring : "",
      session_search_model : [],
      session_search_counter : 0,
    }),

    created () {
      console.log("created");
      // this.$refs.app.$on("search",this.doSearch(searchstring));
      // this.$root.$once("search",this.doSearch);
    },

    watch: {
      selectedVisitor : function(val) { // действие при выделении посетителя в списке

        console.log("selectedVisitor = ", val);
        // this.visitorMenuOpen=true; // раскрытие меню посетителя
        // if(val!==this.selectedVisitor){
        //   this.visitorMenuOpen=true;
        // }
      },
      selected_users : function(val){
        console.log("selected_users = ", val);
      },
      session_searchstring : function(val){
        if(val==""){
          this.session_search_counter = 0;
          this.session_search_model = [];
        }

      },
      selectall : function(val){
        if(val)
          this.selected_users = this.filtered_visitors.map((item) => item.anonymous_id);
        else
          this.selected_users = [];
      },
    },

    mounted () {
      this.focus = new Date().toISOString().split('T')[0]
      this.$refs.calendar.checkChange();
      this.updateVisitors();
      /*const events = [];
      this.sessions = [];
      axios.get("/api/sessions",{params:{date_visit:"2022-02-28"}})
      .then(response => {
        if(response.data.status!="ok"){
          this.error_show=true;
          this.error_text="Ошибка при запросе сеансов";
          return;
        }
        this.sessions.push(response.data.sessions);
        this.sessions.forEach((element) => {
        // console.log("element", element);
        const name = element.name + " " + element.comment;
        const start = new Date(element.datetime_visit);
        const end = new Date(new Date(element.datetime_visit).setMinutes(new Date(element.datetime_visit).getMinutes()+element.duration_minutes));
        console.log("name =", name);
        console.log("start =", start);
        console.log("end =", end);
        events.push({
            name: element.name + element.comments,
            start: start,
            end: end,
            color: "blue",
            timed : true,
        });
        this.events = events;
      });
        // console.log("sessions", response.data.sessions)
        // console.log("this.sessions.length=", this.sessions.length)
      }).catch(error => {
        this.error_show=true;
        this.error_text=error;
      });*/
    },

    computed: {
      // search() {
      //   console.log("Main.vue, computed: search(): searchstring=", searchstring)
      //   var searchstring = this.$store.state.searchstring;
        
      //   return this.doSearch(this.$store.state.searchstring);
      // },

      filtered_visitors() {
        // console.log("filtered_visitors():");
        // console.log("filtered_visitors():this.visitors =", this.visitors);
        var result = this.visitors.filter((item) => {
          // имя фамилия отчество комментарий ID
          // first_name last_name patronymic email phone anonymous_id
          /*if(this.selectedEvent!=undefined){
            return item.anonymous_id==this.selectedEvent.anonymous_id;
          }
          else */if(this.visitor_searchstring === ""){
            return true;
          }
          else {
            var searchstring = this.visitor_searchstring.toLowerCase();
            var result = false;
            if(item.first_name)
              result = result || item.first_name.toLowerCase().includes(searchstring);
            if(item.last_name)
              result = result || item.last_name.toLowerCase().includes(searchstring);
            if(item.patronymic)
              result = result || item.patronymic.toLowerCase().includes(searchstring);
            if(item.comments)
              result = result || item.comments.toLowerCase().includes(searchstring);
            if(item.email)
              result = result || item.email.toLowerCase().includes(searchstring);
            if(item.phone)
              result = result || item.phone.toLowerCase().includes(searchstring);
            if(item.anonymous_id)
              result = result || item.anonymous_id.toLowerCase().includes(searchstring);
            return result;
            // (
            //   item.first_name.toLowerCase().includes(searchstring) ||
            //   item.last_name.toLowerCase().includes(searchstring) ||
            //   item.patronymic.toLowerCase().includes(searchstring) ||
            //   item.comments.toLowerCase().includes(searchstring) ||
            //   item.email.toLowerCase().includes(searchstring) ||
            //   item.phone.toLowerCase().includes(searchstring) ||
            //   item.anonymous_id.toLowerCase().includes(searchstring)
            // )
          }
          
          // return (
          //   (item.name.toLowerCase().match(this.searchline.toLowerCase()) ||
          //     //item.tags.includes(this.searchline.toLowerCase())
          //     item.tags.filter((tag_item) => {
          //       return tag_item.match(this.searchline.toLowerCase());
          //     }).length > 0) &&
          //   //item.tags.includes(this.searchline.toLowerCase() )
          //   (item.price == 0 || (this.searchfreeonly ? item.price == 0 : true))
          // );
        });
        // console.log("filtered_visitors(): result = ", result);
        return result;
      },
    },

    methods: {

      showVisitorContextMenu(e, item ){ // метод для отображения контекстного меню
        e.preventDefault();
        console.log("e = ", e);
        console.log('nativeEvent = ', item);       
        this.x_visitor_menu = e.clientX;
        this.y_visitor_menu = e.clientY;
        this.$nextTick(() => {
          this.visitorMenuOpen = !this.visitorMenuOpen;
        });
      },

      getAge(str_birth_date) {
        return Math.floor(
          (new Date()
           - new Date(str_birth_date).getTime()
           ) / 3.15576e+10);
      },

      newVisitor () {
        this.$router.replace({name:"Visitor"});
      },

      updateVisitors(){
        axios.get( "/api/visitors")
        .then(response => {
          console.log("/api/visitors response.data=",response.data);
          if(response.data.status!="ok") {
            this.error_show=true;
            this.error_text="Ошибка при запросе списка посетителей";
            return;
          }
          this.visitors = [];
          this.visitors= response.data.visitors;
          console.log("updateVisitors(), visitors = ", this.visitors);
        })
        .catch(error => {
          console.log("/api/visitors error=");
          this.error_show=true;
          this.error_text=error;
        });
      },

      confirmDeleteVisitor() {
        this.show_overlay = true;
      },

      editVisitor(){
        // TODO передавать ID, selectedVisitor при вызове контекстного меню не работает
        this.$router.replace(
          {name:"Visitor",
          params: { existingID: this.selectedVisitor.anonymous_id }});
      },

      editSession() {
        // axios.get("/api/session.edit")
        // .then()
        // .catch()

        // перейти на страницу "Session"
        // пометка existing
        this.$router.replace(
          {name:"Session",
          params: { existingID: this.selectedEvent.id }});
      },

      confirmDeleteSession(){
        this.show_overlay_delete_session = true;
      },

      visitorSearch(searchstring){
        console.log("visitorSearch(): searchstring = ", searchstring);
        if(searchstring==="")
          return;
        
      },

      list_item_click(event){
        // this.visitorMenuOpen = false;
        console.log("list_item_click() = event", event);
        this.selectedVisitorElement = event.target;
        // this.visitorMenuOpen = true;
      },

      sessionSearch(){
        //TODO запрос за поиском к серверу

        // тестовые данные
        // this.session_search_model = [{datetime_visit :"2022-06-30"},
        //   {datetime_visit :"2022-06-28"},
        //   {datetime_visit : "2022-06-26"}]

        axios.get( "/api/session.search", { params: {q:this.session_searchstring}})
        .then(response => {
          console.log("sessionSearch(), response = ", response);
          if(response.data.status!="ok"){
            this.$emit("showerror","Ошибка сервера");
            this.session_search_model = [];
            return;
          }
          if(response.data.sessions.length === 0){
            this.$emit("showmessage","Не найдено результатов поиска по сессиям");
            this.session_search_model = [];
            return;
          }
          this.session_search_model = response.data.sessions;
          
          var tmp_date = this.session_search_model[0].datetime_visit.split('T')[0];
          this.focus =  tmp_date;
        })
        .catch(error => {
          this.$emit("showerror","Ошибка сервера");
          this.session_search_model = [];
        })
        
        this.session_search_counter = 0;

      },

      sessionSearchPrevious(){
        var temp_counter = this.session_search_counter + this.session_search_model.length - 1;
        this.session_search_counter = temp_counter % this.session_search_model.length;
        var datetime_string= this.session_search_model[this.session_search_counter].datetime_visit;
        var tmp_date = datetime_string.split('T')[0];
        this.focus =  tmp_date;
      },

      sessionSearchNext(){
        // увеличение счётчика
        var temp_counter = this.session_search_counter + 1;
        this.session_search_counter = temp_counter % this.session_search_model.length;

        // переход к элементу в календаре
        var datetime_string = this.session_search_model[this.session_search_counter].datetime_visit;
        console.log("sessionSearchNext(): this.session_search_model = ", this.session_search_model)
        console.log("sessionSearchNext(): this.session_search_model[] = ", this.session_search_model[this.session_search_counter])
        console.log("sessionSearchNext(): datetime_visit = ", datetime_string);
        var tmp_date = datetime_string.split('T')[0];
        // console.log("sessionSearchNext(): date_visit = ", tmp_date);
        console.log("sessionSearchNext(): date_visit = ", tmp_date);
        this.focus =  tmp_date;

        // найденный элемент в модели календаря
        // перенесено в getEventColor
      },

      deleteVisitor() {
        axios.post( "/api/visitor.delete",
        // {anonymous_ids:this.selected_users} // старая модель, когда можно было выделить несколько
        {anonymous_ids:[this.selectedVisitor.anonymous_id]}
        )
        .then(response => {
          console.log("/api/deletevisitor response.data=",response.data);
          if(response.data.status!="ok") {
            this.$emit("showerror","Не удалось удалить посетителей");
            //this.error_show=true;
            //this.error_text="Не удалось удалить посетителей";
          } else {
            // this.visitors = this.visitors.filter(e => !this.selected_users.includes(e['anonymous_id']));
            this.visitors = this.visitors.filter(e => e['anonymous_id']!==this.selectedVisitor.anonymous_id);
            this.selected_users = [];
            // this.updateVisitors();
          }
        })
        .catch(error => {
          console.log("/api/deletevisitor error=");
          this.$emit("showerror","Не удалось удалить посетителей");
          this.selected_users = [];
          //this.error_show=true;
          //this.error_text=error;
        });
        this.show_overlay = false;
      },

      deleteSession() {
        axios.post("/api/session.delete", {
          session_id : this.selectedEvent.id,
        })
        .then(response => {
          if(response.data.status!="ok") {
            this.$emit("showerror","Не удалось удалить сессию");
            this.selectedEvent = {};
          }
          else {
            this.events = this.events.filter(e => e['id'] !== this.selectedEvent.id);
            this.selectedEvent = {};
            this.$emit("showmessage","Сессия успешно удалена");
          }
        })
        .catch(error => {
          this.$emit("showerror","Не удалось удалить сессию");
        });
        
        this.show_overlay_delete_session = false;
      },

      newSession () { // 
        // console.log("typeof this.focus = ", typeof this.focus);
        this.$router.replace({name:"Session",
        params: {
          visitorID : this.selectedVisitor.anonymous_id,// this.selected_users[0],
          sessionID : null,
          date : this.focus,
          create_new : true,
        }});
      },

      getEventColor (event) { // метод для назначения цвета сессии в календаре
        //TODO разные цвета для текущих, пройденных и следующих сессий
        // console.log("getEventColor(), event = ", event);
        if(this.selectedVisitor!=undefined){
          if(this.selectedVisitor.anonymous_id == event.anonymous_id){// выделение сессий выделенного посетителя
            return 'red';
          }
          else{
            return 'blue';
          }
        }
        else if(this.session_search_model.length > 0){
          var selected_session_ids = this.session_search_model.map((val)=> {return val.id});
 
          if(selected_session_ids.includes(event.id))// выделение сессий по строке поиска
          {
            return 'red';
          }
          else{
            return "blue";
          }
        }
        else
          return "blue";
      },

      setToday () { // кнопка над календарём
        this.focus = new Date().toISOString().split('T')[0]; //'2022-02-28';//new Date("2022-02-28");
      },

      prev () { // кнопка над календарём
        this.$refs.calendar.prev()
      },
      
      next () { // кнопка над календарём
        this.$refs.calendar.next()
      },

      fetchEvents ({ start, end }) { // загрузка запланированных сессий в календарь

        console.log("fetchEvents(), start=",start);
        console.log("fetchEvents(), end=",end);
        // const num_of_days = Math.round((end.getTime() - start.getTime())/(1000 * 60 * 60 * 24));
        let date1 = new Date(start.date);
        console.log("fetchEvents(), start.date=", start.date);
        console.log("fetchEvents(), date_to_request.date=", date1.getDate());
        console.log("fetchEvents(), date_to_request=", date_to_request);
        // console.log("fetchEvents(), Date.toDateString=",new Date().toDateString());
        console.log("fetchEvents(), Date.toISOString=",new Date().toISOString());
        // console.log("fetchEvents(), Date.toLocaleDateString=",new Date().toLocaleDateString());
        // console.log("fetchEvents(), Date.toUTCString=",new Date().toUTCString());
        // console.log("fetchEvents(), Date.toLocaleDateString=",date_to_request.toLocaleDateString('%Y-%m-%d'));
        console.log("fetchEvents(), date_to_request < end=", date_to_request < new Date(end.date));
        this.events = [];
        const tmp_events = [];
        const date2 = new Date(end.date);
        console.log("fetchEvents(), date2 = ", date2.getDate());
        
        for(
          var date_to_request = date1;
          date_to_request <= date2;
          date_to_request.setDate(date_to_request.getDate() + 1))
        {
          console.log("fetchEvents(), date_to_request=", date_to_request);
          // axios.get( "/api/sessions", { params: { date_visit: date_to_request.toISOString() } })
          axios.get( "/api/sessions", { params: { date_visit: date_to_request.toISOString()} })
          .then(response => {
            console.log( "response=", response );
            if(response.data.status!="ok"){
              this.error_show=true;
              this.error_text="Ошибка при запросе сеансов";
              return;
            }
            response.data.sessions.forEach((element) => {
              // console.log("element=", element);
              const tmp_name = element.name;// + " " + element.comments;
              const tmp_start = new Date(element.datetime_visit);
              const tmp_end = new Date(
                new Date(element.datetime_visit).setMinutes(
                  new Date(element.datetime_visit).getMinutes()
                  +element.duration_minutes));

              tmp_events.push({
                  name: tmp_name,
                  start: tmp_start,
                  end: tmp_end,
                  color: "blue",
                  timed : true,
                  id : element.id,
                  comments : element.comments,
                  anonymous_id : element.anonymous_id,
              });
              this.events = tmp_events;
            })
          })
          .catch(error => {
            this.error_show=true;
            this.error_text=error;
          });
          // date_to_request = new Date(new Date().setDate(date_to_request.getDate()+1));
          console.log("fetchEvents(), new date_to_requestnd=",date_to_request);
        }
      },

      showEvent ({ nativeEvent, event }) { // нажатие на сессии, раскрытие меню
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          // console.log("showEvent(), this.selectedElement = ", this.selectedElement);
          // console.log("showEvent(), this.selectedEvent = ", this.selectedEvent);
          requestAnimationFrame(() => requestAnimationFrame(() => this.calendarMenuOpen = true))
          
        }

        if (this.selectedOpen) {
          this.calendarMenuOpen = false
          requestAnimationFrame(
            () => requestAnimationFrame(() => open())
          )
        } else {
          open()
          //TODO выделять посетителя в списке, проматывать список
          // event.anonymous_id
          console.log("selectedVisitor = ", this.selectedVisitor);
          console.log("event.anonymous_id = ", event.anonymous_id);
          const found = this.visitors.find( visitor => visitor.anonymous_id==event.anonymous_id);
          // console.log("found = ", found);
          this.selectedVisitor = found;
          this.visitor_searchstring = event.anonymous_id;     
        }


        // прервать обработку события другими компонентами
        nativeEvent.stopPropagation()
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      intervalFormatter(locale) {
        return locale.time;
      },
    },
  }
</script>
