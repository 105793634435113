<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-sheet
          class="pa-12 mx-auto"
          max-width="500"
          color="grey lighten-3"
          rounded
          elevation="1"
        >

          <v-text-field
            v-model="username"
            :label='$t("login.login")'
            outlined
            clearable
            background-color="white"
            :rules="[rules.required]" 
          ></v-text-field>

          <v-text-field
            v-model="password"
            :label='$t("login.password")'
            outlined
            clearable
            background-color="white"
            :rules="[rules.required]" 
            :type="show1 ? 'text' : 'password'"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-btn
            depressed
            color="primary"
            rounded
            dark
            large
            @click="login"
          >
            {{ $t("login.login_btn")}}
          </v-btn>

        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";  
  export default {
    name: 'Login',

    data: () => ({
      show1: false,
      password: 'op_pass',
      username:'operator',
      timeout:3000,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }),

    methods: {
      login(){
        axios.post("/api/auth",
                  { username:this.username,
                    password:this.password } )
          .then(response => {
            //console.log("response.data = ", response.data);
            const tmp_token = response.data.token;
            if(tmp_token && tmp_token.length==40){
              this.$store.commit('setToken', tmp_token);
              this.$store.commit('setUsername', this.username);
              axios.defaults.headers.common['Authorization'] = "Token "+ tmp_token;
              console.log(localStorage);
              this.$router.replace({name:"Main"});
            }
            else{
              this.$emit('showerror', "Неверный логин или пароль");
            }
          })
          .catch(error => {
            this.$emit('showerror', error);
          })
      }
    },

    beforeMount () {
      // console.log("beforeMount Token=",this.$store.state.token); // I'm text inside the component.
      if(this.$store.state.token && this.$store.state.username){
        this.$router.replace({name:"Main"});
      }
    },
  }
</script>
