import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import Session from '../views/Session.vue'
import Visitor from '../views/Visitor.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Main',
        component: Main,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/session',
        name: 'Session',
        component: Session,
        meta: { requiresAuth: true },
        props : true,
    },
    {
        path: '/visitor',
        name: 'Visitor',
        component: Visitor,
        meta: { requiresAuth: true },
    },
]

const router = new VueRouter({
    routes
})

export default router