<template>
  <v-app>
    <v-app-bar app color="primary" dark clipped-left
      >
      <!-- <v-toolbar-title>Robosculptor</v-toolbar-title> -->
      <!-- <v-toolbar-side-icon> -->
      <!-- <v-toolbar-title> -->
      <!-- <v-avatar tile> -->
      <!-- <v-toolbar-side-icon>
        <v-img
          contain
          max-height="64"
          min-width="150"
          max-width="350"
          class="ml-n5 mr-2 justify-start"
          src="/static/logo.png"
          alt="logo"
          justify-start
        ></v-img>
      </v-toolbar-side-icon> -->

      <!-- логотип -->
      <v-toolbar-side-icon>
        <v-img
            contain
            min-width="150"
            max-width="300"
            class="ml-n5"
            src="/static/logo.png"
            alt="logo"
          ></v-img>
      </v-toolbar-side-icon>

      <!-- <v-toolbar-title class="title"><h2>Schedule of procedures</h2></v-toolbar-title> -->
      <!-- <v-app-bar-title class="grow"><h2>Schedule of procedures</h2></v-app-bar-title> -->
      <v-app-bar-title class="mt-1">
        <h2 justify-start>{{ $t("app.title") }}</h2>
      </v-app-bar-title>
      <!-- </v-avatar>   -->
      <!-- </v-toolbar-side-icon> -->
      <!-- <v-spacer></v-spacer> -->
      <!-- <v-container justify-center class="pa-0 fill-height justify-center"> -->
      <!-- <v-responsive justify-center min-width="500" max-width="1000" class="pa-0 fill-height justify-center">
        <v-text-field
          label="Поиск по ID, названию и комментарию"
          v-if="this.$route.name == 'Main'"
          v-model="searchstring"
          filled
          dense
          rounded
          clearable
          min-width="500"
          class="py-0 fill-height fill-width ml-2 mr-2"
          @keypress.enter="doSearch"
        >
          <template v-slot:append>
            <v-btn color="white" dark icon class="mt-n3 mr-n5" @click="doSearch">
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-responsive> -->
      <!-- </v-container> -->
      <v-spacer></v-spacer>
      <!-- <v-spacer></v-spacer>
      <v-spacer></v-spacer> -->
      <!-- <v-spacer class="fill-width"></v-spacer> -->
      <!-- <v-container
        justify-end
        fluid
        class="pa-0 ma-0 fill-height"
        > -->
      <v-menu
        offset-y
        >
        <template
          v-slot:activator="{ attrs, on }"
          >
          <v-btn
            v-bind="attrs"
            v-on="on"
            outlined
          >
            {{$i18n.locale}}
            <!-- {{$store.state.selected_language}} -->
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in languages"
            :key="item"
            link
          >
            <v-list-item-title
              v-text="item"
              @click="setLanguage(item)"
            ></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- </v-container> -->
      <div justify-end class="pa-0 ma-0 fill-height">
        <v-container
          justify-end
          class="pa-0 ma-0 fill-height"
          v-if="$store.state.token">
          
          <!-- <v-spacer></v-spacer> -->

          <v-divider
            vertical
            class="mx-2 fill-height"
          ></v-divider>

          <v-avatar color="white">
            <v-icon color="primary">
              <!-- mdi-account-multiple -->
              mdi-account-circle
            </v-icon>
          </v-avatar>
          <!-- <div justify="space-around"><p> {{ $store.state.username }} </p></div> -->
          <div class="px-4">
            <span class="text-2xl text-white font-semibold">
              {{$store.state.username}}
            </span>
          </div>
          <v-divider inset vertical class="mx-2"></v-divider>

          <v-btn outlined @click="logout"> {{$t("app.logout")}} </v-btn>

        </v-container>
      </div>
      
    </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main style="height: 100vh;">
      <!-- Provides the application the proper gutter -->
      <v-container fluid fill-height>
        <!-- If using vue-router -->
        <router-view
          @showerror="showerror"
          @showmessage="showmessage"
        ></router-view>
      </v-container>
    </v-main>

    <v-snackbar
      ref="snackbar"
      v-model="error_show"
      :color="shackbar_color"
      :timeout="timeout"
    >
      {{ error_string }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="error_show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      error_show: false,
      error_string: "app error string",
      shackbar_color: "error",
      searchstring: "",
      timeout : 3000,
      languages : ['en','ru'],
      // selected_language : 'en',
    };
  },
  methods: {
    logout() {
      this.$store.commit("setToken", "");
      this.$store.commit("setUsername", "");
      this.$router.replace({ name: "Login" });
    },

    showerror(p_error_string) {
      console.log("showError()");
      this.shackbar_color = "error";
      this.error_string = p_error_string;
      this.error_show = true;
    },

    showmessage(p_message_string) {
      console.log("showError()");
      this.shackbar_color = "primary";
      this.error_string = p_message_string;
      this.error_show = true;
    },

    doSearch() {
      console.log("clicked");
      // this.$emit("search", this.searchstring);
      //this.$store.state.searchstring = this.searchstring;
      this.search = this.searchstring;
    },

    setLanguage(arg) {
      // this.$store.commit("setSelectedLanguage", arg);
      if(this.$i18n.locale !== arg){
        this.$i18n.locale = arg;
      }
    }
  },
  // events: {
  //   showerror(msg){
  //     console.log("showError()");
  //     this.error_string = msg;
  //     this.error_show = true;
  //   },
  // },
  mounted() {
    console.log("this.$route.path = ", this.$route.path);
    console.log("this.$route.name = ", this.$route.name);
  },

  computed: {
    search: {
      get() {
        return this.$store.state.searchstring;
      },

      set(value) {
        this.$store.commit("setSearchstring", value);
      },
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
